<template>
  <div class="studentSituation">
    <el-table
      :data="tableData"
      stripe
      border
      style="width: 100%"
      max-height="700"
      align="center">
      <el-table-column
        prop="admissionBatch"
        label="批次"
        align="center">
      </el-table-column>
      <el-table-column
        prop="totalPeople"
        label="总人数"
        align="center">
      </el-table-column>
      <el-table-column
        prop="numOfPeoplePaid"
        label="已缴费"
        align="center">
        <template slot-scope="scope">
          {{scope.row.numOfPeoplePaid || 0}}
        </template>
      </el-table-column>
      <el-table-column
        prop="numOfUnpaidFees"
        label="未缴费"
        align="center">
        <template slot-scope="scope">
          {{scope.row.numOfUnpaidFees || 0}}
        </template>
      </el-table-column>
      <el-table-column
        prop="noPayNum"
        label="未结清"
        align="center">
        <template slot-scope="scope">
          {{scope.row.noPayNum || 0}}
        </template>
      </el-table-column>
      <el-table-column
        prop="freeNum"
        label="免费"
        align="center">
        <template slot-scope="scope">
          {{scope.row.freeNum || 0}}
        </template>
      </el-table-column>
      <el-table-column
        prop="paymentRatio"
        label="缴费比例"
        align="center">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="查看"
        width="100"
        align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">各站点人数</el-button>
        </template>
      </el-table-column>
    </el-table>
    <PublicDialog :isShow='dialogVisible' :dialogData="dialogData" @dialogEvent="dialogEvent">
      <div class="table_area" slot="dialogCont">
        <el-table
          :data="tableSiteData"
          stripe
          border
          style="width: 100%"
          max-height="400"
          align="center">
          <el-table-column
            prop="stationName"
            label="教学点"
            align="center">
          </el-table-column>
          <el-table-column
            prop="admissionBatch"
            label="批次"
            align="center">
          </el-table-column>
          <el-table-column
            prop="totalPeople"
            label="总人数"
            align="center">
          </el-table-column>
          <el-table-column
            prop="numOfPeoplePaid"
            label="已缴费"
            align="center">
            <template slot-scope="scope">
              {{scope.row.numOfPeoplePaid || 0}}
            </template>
          </el-table-column>
          <el-table-column
            prop="numOfUnpaidFees"
            label="未缴费"
            align="center">
            <template slot-scope="scope">
              {{scope.row.numOfUnpaidFees || 0}}
            </template>
          </el-table-column>
          <el-table-column
            prop="noPayNum"
            label="未结清"
            align="center">
            <template slot-scope="scope">
              {{scope.row.noPayNum || 0}}
            </template>
          </el-table-column>
          <el-table-column
            prop="freeNum"
            label="免费"
            align="center">
            <template slot-scope="scope">
              {{scope.row.freeNum || 0}}
            </template>
          </el-table-column>
          <el-table-column
            prop="paymentRatio"
            label="缴费比例"
            align="center">
          </el-table-column>
        </el-table>
        <!-- <Pagination></Pagination> -->
      </div>
    </PublicDialog>
  </div>
</template>

<script>
import PublicDialog from '../PublicDialog'
import Pagination from '../Pagination'
export default {
  name: 'studentSituation',
  components: {
    PublicDialog,
    Pagination
  },
  data() {
    return {
      dialogVisible: false,
      dialogData: {
        title: '个站点人数',
        dialogStyle: {width: '50%',height: '400px'}
      },
      isShowCount: true,
      loading: false,
      tableData: [],
      tableSiteData: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log(newVal,oldVal)
        if(newVal.name == 'studentSituation') {
          this.querySchoolNum(newVal.query.id);
        }
      }
    }
  },
  methods: {
    // 查看学校在校生人数
    querySchoolNum(id) {
      let obj = { tenant_id: id };
      this.$request.checkSchoolNum(obj, res => {
        if(res.code == 0) {
          this.tableData = res.data;
        }
      })
    },
    handleClick(row) {
      // this.isShowCount = false
      let obj = {
        batch: row.admissionBatch || '',
        tenant_id: row.tenantId
      }
      this.dialogVisible = true;
      this.$request.checkNum(obj, res => {
        if(res.code == 0) {
          this.tableSiteData = res.data;
        }
      })
    },
    dialogEvent(val) {
      this.dialogVisible = val.dialogVisible;
    },
  },
}
</script>

<style lang="less">
  .studentSituation {
    box-sizing: border-box;
    padding: 30px 40px 40px;
    background-color: #fff;
    // ::-webkit-scrollbar {
    //   width: 8px;
    //   height: 8px;
    //   background-color: #fff;
    // }
    // ::-webkit-scrollbar-thumb {
    //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    //     background-color: rgba(0, 0, 0, .1);
    //     border-radius: 3px;
    //     border-top-right-radius: 4px;
    //     border-bottom-right-radius: 4px;
    // }
    // .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
    //     border-right: none;
    // }
    // .el-pagination {
    //   text-align: right;
    // }
  }
</style>