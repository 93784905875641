<template>
  <el-dialog
    ref="ee"
    class="dialog_cont"
    :title="dialogData.title"
    :visible.sync="dialogVisible"
    :width="dialogData.dialogStyle.width"
    @close="handleClose">
    <slot name="dialogCont"></slot>
    <span slot="footer" class="dialog-footer">
      <slot name="cancels"></slot>
      <slot name="enters"></slot>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'PublicDialog',
  props: ["isShow", "dialogData"],
  data() {
    return {
      dialogVisible: this.isShow,
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,777)
        this.dialogVisible = newVal;
        if(newVal) {
          this.initDia()
        }
      }
    },
  },
  methods: {
    initDia() {
      this.$nextTick(()=>{
        this.$refs.ee.$el.firstChild.children[1].style.height = this.dialogData.dialogStyle.height;
      })
    },
    handleClose() {
      let obj = {
        dialogVisible: false
      }
      this.$emit('dialogEvent', obj)
    },
  },
}
</script>

<style lang="less">
  // .dialog_cont {}
</style>